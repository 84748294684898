@use '/styles/mixin/fonts.module.scss' as *;

.d-block {
  ul {
    @apply ml-[1.125rem];
    @apply list-disc;
    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'large');
    }
  }
  ol {
    @apply ml-[1.125rem];
    @apply list-decimal;
    @include font-text('mobile', 'large');
    @screen md {
      @include font-text('desktop', 'large');
    }
  }
  :global(.dcoty-evergreen-how-judge-list) {
    @apply flex;
    flex-wrap: wrap;

    li {
      @include font-subtext('mobile', 'large');
      flex: 1 1 50%;
      @apply mb-4;

      @screen md {
        @include font-subtext('desktop', 'large');
        flex: 1 1 25%;
      }
    }
  }
}
