@use '/styles/mixin/fonts.module.scss' as *;

.drive-review-lead-form {
  &__wrapper {
    [class^='headerSection_drive-header-section__container'] {
      @apply bg-blue-faint;
      @screen lmd {
        @apply pt-3;
      }
      @screen xl {
        @apply pt-6;
      }

      [class^='headerSection_drive-header-section__separator-header'] {
        @apply flex;
        @screen xl {
          @apply hidden;
        }
      }

      [class^='headerSection_drive-header-section__wrapper'] {
        @apply pt-5;
        @screen lmd {
          @apply pt-4;
        }
        @screen xl {
          @apply pt-0;
        }

        [class^='headerSection_drive-header-section__img-wrapper'] {
          @apply items-start;
          @screen lmd {
            @apply min-w-[92px];
            @apply justify-start;
          }
          img {
            @apply rounded-lg;
            @apply w-[92px];
            @apply h-14;
            @apply bg-blue-faint;

            @screen xl {
              @apply w-[128px];
              @apply h-18;
            }
          }
        }
      }

      [class^='headerSection_drive-header-section__description'] {
        @screen lmd {
          @apply pl-3.5;
        }
        @screen xl {
          @apply pl-6;
        }

        [class^='headerSection_drive-header-section__header'] {
          @apply flex;
          @apply items-center;
          @apply mt-0.5;
          @apply mb-1.5;
          h3 {
            @apply m-0;
            @screen lmd {
              @include font-subtext('mobile', 'medium');
            }
            @screen xl {
              @include font-subtext('desktop', 'large');
            }
          }
        }

        [class^='formPriceInfo_drive-cfs-form-price-info__wrapper'] {
          @apply flex;
          @apply justify-center;
          @apply flex-col;
          @apply m-0;

          [class^='formPriceInfo_drive-cfs-form-price-info__price'] {
            @include font-heading('mobile', 'h4');
            @screen xl {
              @include font-heading('desktop', 'h4');
            }
          }

          [class^='formPriceInfo_drive-cfs-form-price-info__price-type'] {
            @apply ml-10;
            @apply text-gray-500;
            @include font-caption('mobile', 'normal');

            @screen xl {
              @include font-caption('desktop', 'normal');
              @apply ml-[52px];
            }
          }
        }
      }
    }

    [class^='enquiryForm_drive-enquiry__container'] {
      @apply bg-blue-faint;
      @apply pt-2.5;

      [class^='enquiryForm_drive-enquiry__header'] {
        @apply mt-0;
        @apply justify-start;
        @apply gap-2.5;
        @screen lmd {
          @apply gap-0;
        }
        img {
          @apply flex;
          @screen lmd {
            @apply mr-2.5;
          }
          @screen xl {
            @apply mr-4;
          }
        }
        h3 {
          @include font-standfirst('desktop');
          @screen lmd {
            @include font-standfirst('mobile');
            @apply my-auto;
          }
          @screen xl {
            @include font-standfirst('desktop');
          }
        }
      }

      [class^='checkboxes'] {
        @apply mt-6;
      }

      [class^='agree-label_drive-cfs-form-agree-label'] {
        @apply text-gray-500;
        @apply pt-6;
        @include font-caption('mobile', 'normal');
        @apply px-2;
        @screen lmd {
          @include font-text('mobile', 'small');
          @apply text-gray-600;
          @apply px-0;
        }
        @screen xl {
          @apply pt-8;
          @include font-text('desktop', 'small');
        }
      }

      [class^='buttons_drive-cfs-form-buttons__button'] {
        @apply mt-6;
        @screen lmd {
          @apply mt-15;
        }
        @screen xl {
          @apply mt-18;
        }
      }
    }

    [class^='confirmation-message_drive-cfs__confirmation-message__wrapper'] {
      @apply bg-blue-faint;
    }

    :global(.error-btn) {
      @apply bg-coral-normal #{!important};
    }

    :global(.label) {
      input[type='checkbox'] {
        &:checked + :global(.checkbox) {
          @apply bg-blue-dark;

          &:after {
            left: 2px;
          }
        }
      }
    }
  }
}
